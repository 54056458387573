import React, { FC, useCallback, useMemo, Suspense } from 'react';
import type { PageBlocksUnion } from '../../graphql/generated/api-graphql';
import * as components from './components';
import type { BlockProps, BlocksProps } from './props';
const LoadingFallback = () => null;
const Blocks: FC<BlocksProps> = ({
  blocks,
  ...otherParams
}) => {
  const renderBlock: any = useCallback((block: PageBlocksUnion & {
    __typename: string;
  }, key: number) => {
    const Block: FC<BlockProps> = (components as any)[block.__typename] ?? null;
    if (!Block) {
      console.warn('Missing block : ' + block.__typename);
      return null;
    }
    return <Suspense key={`${block.__typename}-${key}`} fallback={<LoadingFallback />}>
          <Block index={key} data={block} {...otherParams} />
        </Suspense>;
  }, [otherParams]);
  return <>{useMemo(() => blocks?.map(renderBlock), [blocks, renderBlock])}</>;
};
export default Blocks;