import type { GetServerSidePropsContext, NextPage } from 'next';
import { useMemo } from 'react';
import { isMaintenance, set503Header, setDefaultCacheHeader, setNoCacheHeader, ssrPersistedQuery, transformSeoToMetas } from '../helpers/SSRHelpers';
import { initWithLangAndLabels } from '../i18n';
import { pageBySlug } from '../graphql/Services/Page/queries';
import Template from '../templates/PageTemplate';
import Blocks from '../relay/Blocks';
import { Configuration } from '../graphql/Services';
import type { GetServerSidePropsReturnType, PageProps as CommonPageProps } from '../relay/Common/PageProps';
import Router, { routes } from '../routes/Router';
import BugsnagHelpers from '../helpers/BugsnagHelpers';
import { adPropsTransformer } from '../relay/Common/adPropsTransformer';
import BreadcrumbWrapper from '../relay/Breadcrumb/BreadcrumbWrapper';
import HeaderWrapper from '../relay/Header/HeaderWrapper';
import FooterWrapper from '../relay/Footer/FooterWrapper';
type PageProps = CommonPageProps & {
  openAuthPopin: boolean;
};
const Page: NextPage<PageProps> = ({
  pageData,
  configuration
}) => {
  const breadcrumbEntries = useMemo(() => pageData?.slug === '/' ? undefined : [...(pageData?.parent?.title ? [{
    label: pageData.parent.title,
    href: pageData.parent?.slug
  }] : []), {
    label: pageData?.title
  }], [pageData]);
  const adProps = useMemo(() => adPropsTransformer(pageData, configuration, true), [pageData, configuration]);
  return <Template breadcrumb={<BreadcrumbWrapper entries={breadcrumbEntries} />} header={<HeaderWrapper data={configuration?.header} socials={configuration?.footer?.socialLinks} />} footer={<FooterWrapper data={configuration?.footer} />} adProps={adProps}>
      {pageData && <Blocks blocks={pageData?.blocks} pagesByTemplate={configuration?.pagesByTemplate} template={pageData?.template} />}
    </Template>;
};
export default Page;
export async function getServerSideProps(context: GetServerSidePropsContext): GetServerSidePropsReturnType<PageProps> {
  const i18n = initWithLangAndLabels('fr', {});
  try {
    const slug: string = '/' + (Array.isArray(context?.params?.slug) && context?.params?.slug ? context.params.slug.join('/') : context?.params?.slug ?? '');
    if (slug.indexOf('/_next') === 0) {
      return {
        notFound: true
      };
    }
    const openAuthPopin = context?.query?.openAuthPopin;
    const [pageRs, configurationRs] = await Promise.all([ssrPersistedQuery(pageBySlug, {
      slug
    }), ssrPersistedQuery(Configuration.queries.configuration)]);
    const pageData = pageRs?.data;
    const configuration = configurationRs?.data ?? null;
    if (!configuration || pageRs?.errors) {
      console.log('pageRs errors', pageRs?.errors);
      set503Header(context);
      return {
        props: {
          i18n,
          error: true
        }
      };
    }
    if (isMaintenance(configuration)) {
      set503Header(context);
    } else {
      setDefaultCacheHeader(context);
    }
    if (!pageData) {
      setNoCacheHeader(context);
      return {
        notFound: true,
        props: {
          i18n,
          configuration
        }
      };
    }
    return {
      props: {
        i18n,
        configuration,
        metas: transformSeoToMetas(slug === '/' ? Router.getRouteUrl(routes.home) : Router.getRouteUrl(routes.page, context?.params), pageData?.seo),
        pageData,
        openAuthPopin: openAuthPopin === '1'
      }
    };
  } catch (e: any) {
    BugsnagHelpers?.notify(new Error('Page SSR Error'), event => {
      const {
        resolvedUrl,
        query,
        params
      } = context;
      event.addMetadata('originalError', {
        error: e,
        message: e.message,
        stack: e?.stack
      });
      event.addMetadata('context', {
        resolvedUrl,
        query,
        params
      });
    });
    set503Header(context);
    return {
      props: {
        i18n,
        error: true
      }
    };
  }
}