import dynamic from 'next/dynamic'

// Configure dynamic imports with loading and suspense options
export const ArticleUnionFourItemsBlock = dynamic(
  () => import('./ArticleUnionFourItemsBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const CulinaryArticleFourItemsBlock = dynamic(
  () => import('./CulinaryArticleFourItemsBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const CulinaryFolderFourItemsBlock = dynamic(
  () => import('./CulinaryFolderFourItemsBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const HeroBannerBlock = dynamic(() => import('./HeroBannerBlock'), {
  loading: () => null,
  suspense: true,
})
export const RecipesSearchEngineBlock = dynamic(
  () => import('./RecipesSearchEngineBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const RecipeSevenItemsBlock = dynamic(
  () => import('./RecipeSevenItemsBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const RecipeTopFiveBlock = dynamic(
  () => import('./RecipeTopFiveBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const HeroBannerCheesesBlock = dynamic(
  () => import('./HeroBannerCheesesBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const HeroBannerSliderBlock = dynamic(
  () => import('./HeroBannerSliderBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const PushHpThumbnailBlock = dynamic(
  () => import('./PushHpThumbnailBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const PushHpFullWidthBlock = dynamic(
  () => import('./PushHpFullWidthBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const VideosHpBlock = dynamic(() => import('./VideosHpBlock'), {
  loading: () => null,
  suspense: true,
})
export const BrandsBlock = dynamic(() => import('./BrandsBlock'), {
  loading: () => null,
  suspense: true,
})
export const PlateFourItemsBlock = dynamic(
  () => import('./PlateFourItemsBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const ListCheesesBlock = dynamic(() => import('./ListCheesesBlock'), {
  loading: () => null,
  suspense: true,
})
export const ListCheesesIndexBlock = dynamic(
  () => import('./ListCheesesIndexBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const CulinaryFoldersListBlock = dynamic(
  () => import('./CulinaryFoldersListBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const TitleBodyBlock = dynamic(() => import('./TitleBodyBlock'), {
  loading: () => null,
  suspense: true,
})
export const PlatesListBlock = dynamic(() => import('./PlatesListBlock'), {
  loading: () => null,
  suspense: true,
})
export const PositiveFoodContainerBlock = dynamic(
  () => import('./PositiveFoodContainerBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
export const PushHpCardsBlock = dynamic(() => import('./PushHpCardsBlock'), {
  loading: () => null,
  suspense: true,
})
export const FaqContainerBlock = dynamic(() => import('./FaqContainerBlock'), {
  loading: () => null,
  suspense: true,
})
export const ListArticlesBlock = dynamic(() => import('./ListArticlesBlock'), {
  loading: () => null,
  suspense: true,
})
export const IframeGoodPlanBlock = dynamic(
  () => import('./IframeGoodPlanBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)

export const ContactFormBlock = dynamic(() => import('./ContactFormBlock'), {
  loading: () => null,
  suspense: true,
})
export const H1Block = dynamic(() => import('./H1Block'), {
  loading: () => null,
  suspense: true,
})
export const GoodPlansListBlock = dynamic(
  () => import('./GoodPlansListBlock'),
  {
    loading: () => null,
    suspense: true,
  }
)
