import React, { FC } from 'react';
import { MainLayoutProps } from '../../layouts/MainLayout';
import AccessibleTitle, { AccessibleTitleProps } from '../../components/atoms/AccessibleTitle';
import * as SC from './styled';
export type PageTemplateProps = MainLayoutProps & {
  accessibleTitleProps?: AccessibleTitleProps;
  children?: React.ReactNode;
};
const PageTemplate: FC<PageTemplateProps> = ({
  accessibleTitleProps,
  children,
  ...layoutProps
}) => {
  return <SC.Layout {...layoutProps}>
      {accessibleTitleProps && <AccessibleTitle {...accessibleTitleProps} />}
      {children}
    </SC.Layout>;
};
export default PageTemplate;